import React from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    CreateButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import docStatusSystem from '../docStatusSystem';
import reportP from '../reportP';
import DateInput from '../../../../components/DateInput';
import reportStatus from '../reportStatus';
import exporter from '../../../../utils/exporter';
import reportUsageType from '../reportUsageType';
import SendOrderButton from './SendOrderButton';
import CheckStatusButton from './CheckStatusButton';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import reportReportType from '../reportReportType';
import reportDropoutReason from '../reportDropoutReason';
import documentImport from '../documentImport';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const PButton = (props) => {
    return (
        <reportP.LinkToList
            label={`resources.${resourceConfig.name}.reportP`}
            filter={{
                overEntityIds: props.record.id,
            }}
        />
    );
};


const ResourceListActions = ({
                                 bulkActions,
                                 basePath,
                                 currentSort,
                                 displayedFilters,
                                 exporter,
                                 filters,
                                 filterValues,
                                 onUnselectItems,
                                 resource,
                                 selectedIds,
                                 showFilter,
                                 total,
                                 translate,
                             }) => (
    <Toolbar>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <documentImport.OpenButton params={{docType: 'REPORT'}}/>
        <CreateButton basePath={basePath}/>
        <ExportDocButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </Toolbar>
);

const ResourceListFilter = (props) => (
    <Filter {...props}>
        <TextInput source={'guids'}/>
        <TextInput source={'docnums'}/>
    </Filter>
);

const ResourceList = withStyles(listStyles)(({classes, ...props}) => (
    <List
        {...props}
        filters={<ResourceListFilter/>}
        sort={{field: 'id', order: 'DESC'}}
        actions={<ResourceListActions/>}
        exporter={exporter}>
        <Datagrid rowStyle={documentRowStyle}>
            <TextField source={'id'}/>
            <TextField source={'numdoc'}/>
            <reportReportType.Field source={'reportType'}/>
            <reportStatus.Field source={'reportStatus'}/>
            <errorModel.LinkToRelatedList type={'REPORT'}/>
            <objectSendModel.LinkToRelatedList type={'REPORT'}/>
            <PButton/>
            <SendOrderButton/>
            <CheckStatusButton/>
            <EditButton/>
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({record, translate}) => (
    <span>
    {translate(`resources.${resourceConfig.name}.name`, {smart_count: 1})} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source={'controlId'}/>
            <DateInput
                source={'dateCreate'}
                label={`resources.${resourceConfig.name}.fields.dateCreate`}
            />
            <DateInput source={'date_send'} label={`resources.${resourceConfig.name}.fields.date_send`}/>
            <DateInput
                source={'expirationDate'}
                label={`resources.${resourceConfig.name}.fields.expirationDate`}
            />
            <DateInput
                source={'productionDate'}
                label={`resources.${resourceConfig.name}.fields.productionDate`}
            />
            <docStatusSystem.Input source={'documentSystemStatus'}/>
            <TextInput source={'numdoc'}/>
            <NumberInput source={'alcoholVolume'} step={0.1}/>
            <TextInput source={'omsId'}/>
            <NumberInput source={'orderType'}/>
            <TextInput source={'ownerId'}/>
            <TextInput source={'packingId'}/>
            <TextInput source={'reportId'}/>
            <reportStatus.Input source={'reportStatus'}/>
            <TextInput source={'subjectId'}/>
            <reportUsageType.Input source={'usageType'}/>
            <reportReportType.Input source={'reportType'}/>
            <reportDropoutReason.Input source={'dropoutReason'}/>
            <TextInput source={'accompanyingDocument'}/>
            <TextInput source={'expDate'}/>
            <TextInput source={'expDate72'}/>
            <NumberInput source={'capacity'}/>
            <NumberInput source={'usedInProduction'}/>
            <TextInput source={'vetisGuid'}/>
        </SimpleForm>
    </Create>
);

const ResourceEdit = (props) => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle/>} {...props}>
        <SimpleForm redirect={false}>
            <TextField source={'id'}/>
            <TextInput source={'controlId'}/>
            <DateInput
                source={'dateCreate'}
                label={`resources.${resourceConfig.name}.fields.dateCreate`}
            />
            <DateInput source={'date_send'} label={`resources.${resourceConfig.name}.fields.date_send`}/>
            <DateInput
                source={'expirationDate'}
                label={`resources.${resourceConfig.name}.fields.expirationDate`}
            />
            <DateInput
                source={'productionDate'}
                label={`resources.${resourceConfig.name}.fields.productionDate`}
            />
            <docStatusSystem.Input source={'documentSystemStatus'}/>
            <TextInput source={'numdoc'}/>
            <NumberInput source={'alcoholVolume'} step={0.1}/>
            <TextInput source={'omsId'}/>
            <NumberInput source={'orderType'}/>
            <TextInput source={'ownerId'}/>
            <TextInput source={'packingId'}/>
            <TextInput source={'reportId'}/>
            <reportStatus.Input source={'reportStatus'}/>
            <TextInput source={'subjectId'}/>
            <reportUsageType.Input source={'usageType'}/>
            <reportReportType.Input source={'reportType'}/>
            <reportDropoutReason.Input source={'dropoutReason'}/>
            <TextInput source={'accompanyingDocument'}/>
            <TextInput source={'expDate'}/>
            <TextInput source={'expDate72'}/>
            <NumberInput source={'capacity'}/>
            <NumberInput source={'usedInProduction'}/>
            <TextInput source={'vetisGuid'}/>
        </SimpleForm>
    </Edit>
);

const ResourceStringField = ({record}) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
    <ReferenceField reference={resourceConfig.name} {...props}>
        <ResourceStringField/>
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField reference={resourceConfig.name} {...props}>
        <SingleFieldList>
            <ChipField source={'id'}/>
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput reference={resourceConfig.name} {...props}>
        <SelectInput optionText={resourceToString}/>
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput reference={resourceConfig.name} {...props}>
        <SelectArrayInput optionText={resourceToString}/>
    </ReferenceArrayInput>
);

export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    menuConfig: {
        name: resourceConfig.name,
        icon: ResourceIcon,
    },
    locales: resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
            expirationDate: {
                type: 'dateTime',
            },
            productionDate: {
                type: 'dateTime',
            },
            dateCreate: {
                type: 'dateTime',
            },
            date_send: {
                type: 'dateTime',
            },
        },
    },
};
