import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Отчет об использов. |||| Отчеты об использов.',
      reportP: 'Позиции отчета',
       sendDoc: {
        action: 'Отправить'
      },
      checkReport:{
        action: 'Проверка',
      },
      fields: {
        id: '#',
        dateCreate: 'Дата создания',
        date_send: 'Дата отправки',
        expirationDate: 'Годен до',
        productionDate: 'Дата производства',
        controlId: 'Id контроля качества',
        documentSystemStatus: 'Системный статус документа',
        numdoc: 'Номер документа',
        omsId: 'OMS Id',
        orderType: 'Тип заказа',
        ownerId: 'Id владельца',
        packingId: 'Id упаковки',
        reportId: 'Id отчета',
        reportStatus: 'Статус отчета',
        seriesNumber: 'Серийные номера',
        subjectId: 'Id темы',
        usageType: 'Тип использования',
        accompanyingDocument: 'Производственный ветеринарный сопроводительный документ',
        expDate: 'Дата окончания срока годности продукции (срок хранения более 72 часов)',
        expDate72: 'Дата окончания срока годности продукции (срок хранения менее 72 часов)',
        capacity: 'Объем',
        usedInProduction: 'Признак использования КМ на производстве',
        reportType:'Тип отчета',
        dropoutReason: 'Причина выбытия',
        guids: 'GUID (Список)',
        docnums: 'Номер документа (Список)',
        alcoholVolume: 'Доля этилового спирта'
      },
    }
  }
}