import resourceConfig from '../config';

export default {
    resources: {
        [resourceConfig.name]: {
            enums: {
                UNIT: 'Единица товара',
                LEVEL1: 'Упаковка 1-го уровня',
                LEVEL2: 'Упаковка 2-го уровня',
                LEVEL3: 'Упаковка 3-го уровня',
                LEVEL4: 'Упаковка 4-го уровня',
                LEVEL5: 'Упаковка 5-го уровня',
                BUNDLE: 'Комплект ',
                SET: 'Набор ',
                BOX: 'Короб',
                GROUP: 'Групповая потребительская упаковка'
            },
        },
    },
};
